import { Section } from "@/components";
import { theme } from "@/styles";
import styled from "@emotion/styled";
import React from "react";

import drawing1 from "./drawing-1.svg";
import drawing2 from "./drawing-2.png";
import drawing3 from "./drawing-3.png";

const Number = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  color: ${theme.colors.yellow};
  opacity: 0.6;
  z-index: -1;
  user-select: none;
  transform: translateY(50%);
`;

const OurPathway = () => (
  <Section
    id="our-pathway"
    classNames="uk-position-relative uk-margin-xlarge-bottom"
  >
    <div
      className="uk-position-absolute uk-position-top-left no-rocket-mobile"
      style={{
        backgroundImage: `url(${drawing2})`,
        height: "160px",
        width: "180px",
        left: "236px",
        transform: "rotate(135deg)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    />

    <div
      className="uk-position-absolute uk-position-bottom-left hand-no-mobile"
      style={{
        backgroundImage: `url(${drawing1})`,
        height: "300px",
        width: "500px",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        bottom: " -220px",
      }}
    />

    <div
      className="uk-position-absolute uk-position-bottom-right stair-mobile"
      style={{
        backgroundImage: `url(${drawing3})`,
        height: "192px",
        width: "200px",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        right: "134px",
        bottom: "34px",
      }}
      data-uk-parallax="bgy: 20, -70"
    />

    <div className="uk-container uk-container-small our-pathway-maxheight">
      <h2 className="uk-text-center">Our Process</h2>

      <div className="uk-grid uk-child-width-1-3@s" data-uk-grid>
        <div className="uk-flex uk-flex-center uk-flex-middle uk-position-relative">
          <div className="uk-width-expand uk-height-small">
            <div className="uk-position-absolute">
              <h4>Learn</h4>
              <p>
                We believe in learning through doing. Although you’re put in the
                deep-end, you’ll have support from your team members & the
                Momentium community to make sure you excel at what you do.
              </p>
              <Number className="uk-heading-xlarge uk-margin-remove">1</Number>
            </div>
          </div>
        </div>
        <div className="uk-flex uk-flex-center uk-flex-middle uk-position-relative our-pathway-height">
          <div className="uk-width-expand uk-height-medium">
            <div className="uk-position-absolute">
              <h4>Network</h4>
              <p>
                Momentium isn’t just a digital services company, we’re a network
                of passionate individuals building their career. Join us to meet
                other passionate people & see where these connections can take
                you.
              </p>
              <Number className="uk-heading-xlarge uk-margin-remove">2</Number>
            </div>
          </div>
        </div>
        <div className="uk-flex uk-flex-center uk-flex-middle uk-position-relative">
          <div className="uk-width-expand uk-height-large">
            <div className="uk-position-absolute">
              <h4>Stand out</h4>
              <p>
                Real-world work experience in your dream industry is hard to
                come by while you’re studying. Through Momentium, you can
                stand-out from the crowd with industry experience before
                graduating.
              </p>
              <Number className="uk-heading-xlarge uk-margin-remove">3</Number>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
);

export default OurPathway;
