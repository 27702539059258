import { Image, Section } from "@/components";
import { DataContext } from "@/pages/for-students";
import { cx } from "emotion";
import { get, map } from "lodash";
import { RichText } from "prismic-reactjs";
import React, { useContext } from "react";

const SuccessStories = () => {
  const data = useContext(DataContext);
  const items = get(
    data,
    "prismic.allFor_students_pages.edges[0].node.success_stories"
  );

  if (!items) return null;
  return (
    <Section id="success-stories">
      <div className="uk-container uk-container-small">
        <h2 className="uk-text-center uk-margin-xlarge-bottom">
          Success Stories
        </h2>

        {map(items, (item, index) => (
          <div
            key={index}
            className="uk-grid uk-flex-middle uk-margin-large-bottom"
            data-uk-grid
          >
            <div className="uk-width-1-3@s uk-text-center">
              <Image
                {...item.avatar}
                className="uk-border-circle"
                style={{ maxHeight: "200px", maxWidth: "200px" }}
              />
            </div>
            <div
              className={cx(
                "uk-width-2-3@s",
                (index + 1) % 2 === 0 && "uk-flex-first@s"
              )}
            >
              <blockquote className="uk-text-normal">
                <p className="uk-margin-bottom">
                  {RichText.asText(item.quote)}
                </p>
                <footer className="uk-text-center">
                  {RichText.asText(item.person)}
                </footer>
              </blockquote>
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default SuccessStories;
