import styled from "@emotion/styled";
import { Link } from "gatsby";
import React, { Component } from "react";

const StyledFooter = styled.footer`
  color: ${(props) => props.theme.colors.grey};
`;

class Footer extends Component {
  render() {
    return (
      <StyledFooter className="uk-section" style={{ position: "relative" }}>
        <div className="uk-container uk-container-small">
          <div
            className="uk-grid uk-child-width-1-2 uk-child-width-1-4@s"
            data-uk-grid
          >
            <p className="uk-text-small">Privacy Policy</p>
            <ul className="uk-nav">
              <li>
                For clients: <Link to="/contact-us"> Service Enquires </Link>
              </li>
              <li>
                For students: <Link to="/join-us"> Career Enquires </Link>
              </li>
            </ul>
            <div className="uk-text-small">
              <a
                href="https://demo.momentium.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                Momentium Portal
              </a>
            </div>
            <div className="uk-text-small">
              <div className="uk-margin-small-bottom">Connect with us: </div>
              <a
                href="https://au.linkedin.com/company/momentium-consultancy"
                className="uk-icon-button uk-margin-small-right"
                uk-icon="linkedin"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  data-svg="linkedin"
                >
                  <path d="M5.77,17.89 L5.77,7.17 L2.21,7.17 L2.21,17.89 L5.77,17.89 L5.77,17.89 Z M3.99,5.71 C5.23,5.71 6.01,4.89 6.01,3.86 C5.99,2.8 5.24,2 4.02,2 C2.8,2 2,2.8 2,3.85 C2,4.88 2.77,5.7 3.97,5.7 L3.99,5.7 L3.99,5.71 L3.99,5.71 Z"></path>
                  <path d="M7.75,17.89 L11.31,17.89 L11.31,11.9 C11.31,11.58 11.33,11.26 11.43,11.03 C11.69,10.39 12.27,9.73 13.26,9.73 C14.55,9.73 15.06,10.71 15.06,12.15 L15.06,17.89 L18.62,17.89 L18.62,11.74 C18.62,8.45 16.86,6.92 14.52,6.92 C12.6,6.92 11.75,7.99 11.28,8.73 L11.3,8.73 L11.3,7.17 L7.75,7.17 C7.79,8.17 7.75,17.89 7.75,17.89 L7.75,17.89 L7.75,17.89 Z"></path>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/momentiumconsulting/"
                className="uk-icon-button uk-margin-small-right"
                uk-icon="facebook"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  data-svg="facebook"
                >
                  <path d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z"></path>
                </svg>
              </a>
              <a
                href="https://www.instagram.com/momentium_au/?hl=en"
                className="uk-icon-button"
                uk-icon="instagram"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  data-svg="instagram"
                >
                  <path d="M13.55,1H6.46C3.45,1,1,3.44,1,6.44v7.12c0,3,2.45,5.44,5.46,5.44h7.08c3.02,0,5.46-2.44,5.46-5.44V6.44 C19.01,3.44,16.56,1,13.55,1z M17.5,14c0,1.93-1.57,3.5-3.5,3.5H6c-1.93,0-3.5-1.57-3.5-3.5V6c0-1.93,1.57-3.5,3.5-3.5h8 c1.93,0,3.5,1.57,3.5,3.5V14z"></path>
                  <circle cx="14.87" cy="5.26" r="1.09"></circle>
                  <path d="M10.03,5.45c-2.55,0-4.63,2.06-4.63,4.6c0,2.55,2.07,4.61,4.63,4.61c2.56,0,4.63-2.061,4.63-4.61 C14.65,7.51,12.58,5.45,10.03,5.45L10.03,5.45L10.03,5.45z M10.08,13c-1.66,0-3-1.34-3-2.99c0-1.65,1.34-2.99,3-2.99s3,1.34,3,2.99 C13.08,11.66,11.74,13,10.08,13L10.08,13L10.08,13z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </StyledFooter>
    );
  }
}

export default Footer;
