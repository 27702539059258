// -- The HTML Serializer
// This function will be used to modify the way that a Rich Text or Title field is rendered.

import { Link } from "gatsby";
import { Link as PrismicLink, RichText } from "prismic-reactjs";
import React from "react";

// We don't want to import every PrismJS component - so that's why they're required individually
const Prism = require("prismjs");
require("prismjs/components/prism-javascript");
require("prismjs/components/prism-css");
require("prismjs/components/prism-scss");
require("prismjs/components/prism-jsx");
require("prismjs/components/prism-bash");
require("prismjs/components/prism-json");
require("prismjs/components/prism-diff");
require("prismjs/components/prism-markdown");
require("prismjs/components/prism-graphql");

const { Elements } = RichText;

// Labels with this name will be inline code
const codeInline = ["text"];
// Labels with these names will become code blocks
const codeBlock = [
  "javascript",
  "css",
  "scss",
  "jsx",
  "bash",
  "json",
  "diff",
  "markdown",
  "graphql"
];

const htmlSerializer = (type, element, content, children, index) => {
  // First differentiate between a label and a preformatted field (e.g. the Code Block slice)
  if (type === Elements.label) {
    // Use the inline code for labels that are in the array of "codeInline"
    if (codeInline.includes(element.data.label)) {
      return `<code class="language-${element.data.label}">${content}</code>`;
    }
    // Use the blockquote for labels with the name "quote"
    if (element.data.label === "quote") {
      return `<blockquote><p>${content}</p></blockquote>`;
    }
    // Use the code block for labels that are in the array of "codeBlock"
    // Choose the right PrismJS highlighting with the label name
    if (codeBlock.includes(element.data.label)) {
      return `<pre class="language-${element.data.label}"><code class="language-${
        element.data.label
      }">${Prism.highlight(content, Prism.languages[element.label])}</code></pre>`;
    }
    return null;
  }
  if (type === Elements.preformatted) {
    if (codeBlock.includes(element.label)) {
      return `<pre class="language-${element.label}"><code class="language-${
        element.label
      }">${Prism.highlight(element.text, Prism.languages[element.label])}</code></pre>`;
    }
    return null;
  }

  // Generate links to Prismic Documents as <Link> components
  if (type === Elements.hyperlink) {
    let result = "";
    const url = PrismicLink.url(element.data, linkResolver);

    if (element.data.link_type === "Document") {
      result = (
        <Link to={url} key={index}>
          {content}
        </Link>
      );
    } else {
      const target = element.data.target ? { target: element.data.target, rel: "noopener" } : {};
      result = (
        <a href={url} {...target} key={index}>
          {content}
        </a>
      );
    }
    return result;
  }

  // If the image is also a link to a Prismic Document, it will return a <Link> component
  if (type === Elements.image) {
    let result = (
      <img src={element.url} alt={element.alt || ""} copyright={element.copyright || ""} />
    );

    if (element.linkTo) {
      const url = PrismicLink.url(element.linkTo, linkResolver);

      if (element.linkTo.link_type === "Document") {
        result = (
          <Link to={url} key={index}>
            {result}
          </Link>
        );
      } else {
        const target = element.linkTo.target
          ? { target: element.linkTo.target, rel: "noopener" }
          : {};
        result = (
          <a href={url} {...target}>
            {result}
          </a>
        );
      }
    }
    const wrapperClassList = [element.label || "", "block-img"];
    result = (
      <p className={wrapperClassList.join(" ")} key={index}>
        {result}
      </p>
    );
    return result;
  }

  return null;
};

const linkResolver = doc => {
  if (doc.type === "homepage") return "/";
  if (doc.type === "what_we_do_page") return "/what-we-do";
  if (doc.type === "who_we_are_page") return "/who-we-are";
  if (doc.type === "for_students_page") return "/for-students";

  if (doc.type === "project") return `/portfolio/${doc.uid}`;
  if (doc.type === "category") return `/category/${doc.uid}`;
  if (doc.type === "blog_post") return `/blogs/${doc.uid}`;

  // Backup for all other types
  return "/";
};

export { linkResolver, htmlSerializer };
