import { Button, Section } from "@/components";
import {
  OurPathway,
  SuccessStories
} from "@/components/sections/ForStudentsPage";
import { theme } from "@/styles";
import styled from "@emotion/styled";
import { StaticQuery, graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic-graphql";
import React from "react";

const ScrollButton = styled.a`
  background-color: ${theme.colors.yellow};
`;

export const DataContext = React.createContext({});
export const query = graphql`
  query {
    prismic {
      allFor_students_pages {
        edges {
          node {
            success_stories {
              avatar
              person
              quote
            }
          }
        }
      }
    }
  }
`;

const ForStudentsPage = ({ data }) => (
  <DataContext.Provider value={data}>
    <Section>
      <div className="uk-container uk-container-small">
        <div className="uk-width-2-3@s">
          <h2 className="uk-h2">Looking to get paid industry experience?</h2>
          <p>
            If you’re looking to get some real world experience before you apply
            for your dream job, considering joining an up-skilling platform
            built for you. Momentium has helped multiple cohorts of students
            kick-start their career.
          </p>
          <ScrollButton
            className="uk-button uk-button-small uk-link-reset uk-border-rounded"
            href="#our-pathway"
            data-uk-scroll
          >
            Learn More
          </ScrollButton>
        </div>
      </div>
    </Section>
    <OurPathway />
    <SuccessStories />

    <section className="uk-section uk-section-large uk-text-center uk-position-relative">
      <h2>Your future starts here.</h2>
      <Button.ExternalLink
        color="yellow"
        href={process.env.GATSBY_APPLY_NOW_LINK}
        target="_blank"
      >
        Apply now
      </Button.ExternalLink>
    </section>
  </DataContext.Provider>
);

ForStudentsPage.query = query;

export default () => (
  <StaticQuery
    query={`${query}`}
    render={withPreview(
      data => (
        <ForStudentsPage data={data} />
      ),
      query
    )}
  />
);
